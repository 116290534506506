import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7be9e1c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quote-details" }
const _hoisted_2 = { class: "quote-details-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    ($setup.initLoading)
      ? (_openBlock(), _createBlock($setup["Loader"], {
          key: 0,
          loading: $setup.initLoading
        }, null, 8, ["loading"]))
      : ($setup.isQuoteDeleted)
        ? (_openBlock(), _createBlock($setup["QuoteIsDeleted"], { key: 1 }))
        : ($setup.isQuoteOnCosting)
          ? (_openBlock(), _createBlock($setup["QuoteIsOnCosting"], { key: 2 }))
          : ($setup.quoteExist)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                ($setup.isCustomerRole)
                  ? (_openBlock(), _createBlock($setup["QuoteDetailsHeaderCustomer"], {
                      key: 0,
                      quote: $setup.quote,
                      team: $setup.quoteTeam,
                      disableCheckout: $setup.disableCheckout,
                      currencies: $setup.currencies,
                      onUpdateQuoteCurrency: $setup.onUpdateCurrency,
                      costingVersion: $setup.costingVersion,
                      initTeamLoading: $setup.initTeamLoading,
                      userInfo: $setup.userInfo,
                      partsTotalCount: $setup.partsTotalCount,
                      onAction: $setup.onAction,
                      onOnProjectNameUpdate: $setup.onProjectNameUpdate,
                      onDeleteQuote: $setup.deleteQuoteAction,
                      onShowInviteModal: _cache[0] || (_cache[0] = ($event: any) => ($setup.fetchQuoteV2Team(false)))
                    }, null, 8, ["quote", "team", "disableCheckout", "currencies", "costingVersion", "initTeamLoading", "userInfo", "partsTotalCount"]))
                  : (_openBlock(), _createBlock($setup["QuoteDetailsHeaderAdmin"], {
                      key: 1,
                      quote: $setup.quote,
                      team: $setup.quoteTeam,
                      disableCheckout: $setup.disableCheckout,
                      partsToReviewCount: $setup.partsToReviewCount,
                      initTeamLoading: $setup.initTeamLoading,
                      initRevisionListLoading: $setup.initRevisionListLoading,
                      quoteRevisionList: $setup.quoteRevisionList,
                      partsTotalCount: $setup.partsTotalCount,
                      currencies: $setup.currencies,
                      costingVersion: $setup.costingVersion,
                      shippingAddress: $setup.shippingAddress,
                      partProductionLocation: $setup.quoteOptions?.totalPartProduction?.location,
                      onAction: $setup.onAction,
                      onRevisionCreated: $setup.onRevisionCreated,
                      onRevisionDeleted: $setup.onRevisionDeleted,
                      onSwitchToRevision: $setup.onSwitchToRevision,
                      onDeleteQuote: $setup.deleteQuoteAction,
                      onUploadCostingFile: $setup.fetchPartList,
                      onUpdateQuoteCurrency: $setup.onUpdateCurrency,
                      onExportOfQuoteFilesRequested: _cache[1] || (_cache[1] = ($event: any) => ($setup.onExportOfQuoteFilesRequested())),
                      onShippingAddressError: $setup.onShowShippingAddressError,
                      onShowInviteModal: _cache[2] || (_cache[2] = ($event: any) => ($setup.fetchQuoteV2Team(false))),
                      onCreateBid: $setup.onCreateBid
                    }, null, 8, ["quote", "team", "disableCheckout", "partsToReviewCount", "initTeamLoading", "initRevisionListLoading", "quoteRevisionList", "partsTotalCount", "currencies", "costingVersion", "shippingAddress", "partProductionLocation"])),
                _createElementVNode("div", _hoisted_2, [
                  ($setup.isQuoteAllowedRole && !$setup.isCustomerRole)
                    ? (_openBlock(), _createBlock($setup["SummarySection"], {
                        key: 0,
                        disabled: $setup.disableCheckout,
                        quote: $setup.quote,
                        partsTotalCount: $setup.partsTotalCount,
                        userInfo: $setup.userInfo,
                        initTeamLoading: $setup.initTeamLoading,
                        currencySymbol: $setup.currencySymbol,
                        initSummaryLoading: $setup.initSummaryLoading,
                        onProjectNameUpdate: $setup.onProjectNameUpdate
                      }, null, 8, ["disabled", "quote", "partsTotalCount", "userInfo", "initTeamLoading", "currencySymbol", "initSummaryLoading"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: _normalizeClass(["options-section", {customer: $setup.isCustomerRole}])
                  }, [
                    _createVNode($setup["QuoteDetailsOptions"], {
                      options: $setup.options,
                      partProductionLocations: $setup.quote.partProductionLocations,
                      shippingAddress: $setup.shippingAddress,
                      shippingAddressError: $setup.shippingAddressError,
                      userInfo: $setup.userInfo,
                      editable: !$setup.quote.isDeleted && $setup.quote.status !== $setup.QuoteAdminStatuses.Ordered,
                      orderedAt: $setup.quote.orderedAt,
                      countOfWorkingDays: $setup.countOfWorkingDays,
                      countryChinaId: $setup.countryChinaId,
                      createdAt: $setup.quote.createdAt,
                      costingVersion: $setup.costingVersion,
                      initOptionsLoading: $setup.initOptionsLoading,
                      optionsLoading: $setup.optionsLoading,
                      rfqPartsHaveDifferentQCOptions: $setup.rfqPartsHaveDifferentQCOptions,
                      onChangeQualityControl: $setup.onChangeQuoteQualityControl,
                      onUpdatePartsProduction: $setup.onUpdatePartsProduction,
                      onUpdateShipping: $setup.onUpdateShipping,
                      onUpdateShippingInformation: $setup.onUpdateShippingInformation
                    }, null, 8, ["options", "partProductionLocations", "shippingAddress", "shippingAddressError", "userInfo", "editable", "orderedAt", "countOfWorkingDays", "countryChinaId", "createdAt", "costingVersion", "initOptionsLoading", "optionsLoading", "rfqPartsHaveDifferentQCOptions"]),
                    _createVNode($setup["QuoteDetailsOptionsSummary"], {
                      quote: $setup.quote,
                      options: $setup.options,
                      checkoutDisabled: $setup.disableCheckout,
                      team: $setup.quoteTeam,
                      shippingAddress: $setup.shippingAddress,
                      countryChinaId: $setup.countryChinaId,
                      partsTotalCount: $setup.partsTotalCount,
                      countOfWorkingDays: $setup.countOfWorkingDays,
                      shippingAddressError: $setup.shippingAddressError,
                      currencySymbol: $setup.currencySymbol,
                      initOptionsLoading: $setup.initOptionsLoading,
                      summaryLoading: $setup.summaryLoading,
                      initSummaryLoading: $setup.initSummaryLoading,
                      initTeamLoading: $setup.initTeamLoading,
                      onShippingAddressError: _cache[3] || (_cache[3] = ($event: any) => ($setup.shippingAddressError = true)),
                      onUpdate: _cache[4] || (_cache[4] = ($event: any) => ($setup.fetchQuoteById(false)))
                    }, null, 8, ["quote", "options", "checkoutDisabled", "team", "shippingAddress", "countryChinaId", "partsTotalCount", "countOfWorkingDays", "shippingAddressError", "currencySymbol", "initOptionsLoading", "summaryLoading", "initSummaryLoading", "initTeamLoading"])
                  ], 2),
                  _createVNode($setup["PartsAndSpecifications"], {
                    parts: $setup.parts,
                    partsTotalCount: $setup.partsTotalCount,
                    partsCount: $setup.partsCount,
                    partStickers: $setup.partStickers,
                    loadingIndex: $setup.loadingIndex,
                    filterList: $setup.filterList,
                    partsLoading: $setup.partsLoading,
                    isClearFilter: $setup.isClearFilter,
                    isShowRemoveButton: $setup.isShowRemoveButton,
                    productionLocation: $setup.productionLocation,
                    rfqPartAttachments: $setup.rfqPartAttachments,
                    uneditableQuote: $setup.uneditableQuote,
                    perPage: $setup.partLimit,
                    isQuoteDeleted: $setup.quote.isDeleted,
                    massProduction: $setup.quote.massProduction,
                    prototypes: $setup.quote.prototypes,
                    showError: $setup.showError,
                    errorPartStatusesIds: $setup.errorPartStatusesIds,
                    errorPartIndexes: $setup.errorPartIndexes,
                    quoteStatus: $setup.quote.status,
                    orderedAt: $setup.quote.orderedAt,
                    contactUser: $setup.contactUser,
                    currencySymbol: $setup.currencySymbol,
                    currencyId: $setup.currencyId,
                    partStatuses: $setup.partStatusesInfo.hasStatuses,
                    costingVersion: $setup.costingVersion,
                    countOfWorkingDays: $setup.countOfWorkingDays,
                    createdAt: $setup.quote.createdAt,
                    countryChinaId: $setup.getCountryIdByRegion($setup.productionLocation, $setup.countryChinaId),
                    initSummaryLoading: $setup.initSummaryLoading,
                    feedbackStatusLoading: $setup.feedbackStatusLoading,
                    feedbackStatuses: $setup.feedbackStatuses,
                    onChangePartQualityControl: $setup.onChangePartQualityControl,
                    onUpdateProductionSpeed: $setup.onUpdateProductionSpeed,
                    onUpdateActiveQuantity: $setup.onUpdateActiveQuantity,
                    onUpdateFeedbacks: $setup.updatePartFeedbacks,
                    onSavePosition: $setup.savePosition,
                    onGetParts: $setup.filterRfqParts,
                    onNewPartsAdded: $setup.onNewPartsAdded,
                    onQuantityAdded: $setup.onQuantityAdded,
                    onRemoveQuantity: $setup.onRemoveQuantity,
                    onScheduleQuantity: $setup.onScheduleQuantity,
                    onDeletePart: $setup.onDeletePart,
                    onUpdatePartConfirmation: $setup.onUpdatePartConfirmation,
                    onCostingVersionsUpdated: $setup.onCostingVersionsUpdated
                  }, null, 8, ["parts", "partsTotalCount", "partsCount", "partStickers", "loadingIndex", "filterList", "partsLoading", "isClearFilter", "isShowRemoveButton", "productionLocation", "rfqPartAttachments", "uneditableQuote", "isQuoteDeleted", "massProduction", "prototypes", "showError", "errorPartStatusesIds", "errorPartIndexes", "quoteStatus", "orderedAt", "contactUser", "currencySymbol", "currencyId", "partStatuses", "costingVersion", "countOfWorkingDays", "createdAt", "countryChinaId", "initSummaryLoading", "feedbackStatusLoading", "feedbackStatuses"])
                ])
              ], 64))
            : (_openBlock(), _createBlock($setup["PageNotFound"], {
                key: 4,
                class: "page-not-found--wrapper"
              })),
    ($setup.showDeleteQuoteModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 5,
          class: "delete-modal",
          show: $setup.showDeleteQuoteModal,
          "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => (($setup.showDeleteQuoteModal) = $event)),
          text: "Are you sure you want to delete this quote?\nAfter deleted, quote cannot be restored.",
          title: "Delete Quote",
          actionText: "Yes, delete",
          onAction: $setup.deleteQuote,
          onClose: $setup.closeDeleteQuoteModal
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showCanNotDeleteModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 6,
          class: "info-modal",
          show: $setup.showCanNotDeleteModal,
          "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => (($setup.showCanNotDeleteModal) = $event)),
          text: "This quote is connected to one or more orders,\ntherefore cannot be deleted at this stage.",
          title: "Delete Quote",
          "info-modal": "",
          onClose: $setup.closeCanNotDeleteModal
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showCanNotChangeStatusModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 7,
          class: "info-modal",
          show: $setup.showCanNotChangeStatusModal,
          "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => (($setup.showCanNotChangeStatusModal) = $event)),
          text: `You cannot ${$setup.statusKey} a quote without parts. Please add to continue`,
          title: "Change status",
          "info-modal": "",
          onClose: $setup.closeCanNotChangeStatusModal
        }, null, 8, ["show", "text"]))
      : _createCommentVNode("", true),
    ($setup.showTechnicalReviewRequiredModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 8,
          class: "info-modal review-required-modal",
          show: $setup.showTechnicalReviewRequiredModal,
          "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => (($setup.showTechnicalReviewRequiredModal) = $event)),
          text: $setup.technicalReviewRequiredMessage,
          title: "Technical review required",
          infoModal: true,
          onClose: _cache[9] || (_cache[9] = ($event: any) => ($setup.showTechnicalReviewRequiredModal = false))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showExportOfQuoteFilesRequestedModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 9,
          class: "info-modal",
          show: $setup.showExportOfQuoteFilesRequestedModal,
          "onUpdate:show": _cache[10] || (_cache[10] = ($event: any) => (($setup.showExportOfQuoteFilesRequestedModal) = $event)),
          text: $setup.exportOfQuoteFilesRequestedMessage,
          title: "Files are being prepared",
          infoModal: true,
          onClose: _cache[11] || (_cache[11] = ($event: any) => ($setup.showExportOfQuoteFilesRequestedModal = false))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showChooseCostingVersionModal)
      ? (_openBlock(), _createBlock($setup["SelectCostingMethodModal"], {
          key: 10,
          show: $setup.showChooseCostingVersionModal,
          "onUpdate:show": _cache[12] || (_cache[12] = ($event: any) => (($setup.showChooseCostingVersionModal) = $event)),
          onUpdate: _cache[13] || (_cache[13] = (value) => $setup.onCostingVersionPicked(value)),
          onClose: _cache[14] || (_cache[14] = ($event: any) => ($setup.onCostingVersionPicked(false)))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true)
  ]))
}